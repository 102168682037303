var ajaxRoute =  '/JFML/server.php';
$(document).foundation();
$(document).ready(function($) {
 // $('#menu-principal').foundation('toggleMenu');
    if ($.specificJs) $.specificJs($);
    /*
    $.specificJs = function() {
            
    }
  */
});

